
import { ref, watch } from '@vue/composition-api';
import Campaign from '@/models/Campaign';
import Company from '@/models/Company';

export default function useCampaignHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const campaignLocal = ref(JSON.parse(JSON.stringify(props.campaign.value)));

    const resetCampaignLocal = () => {
        campaignLocal.value = JSON.parse(JSON.stringify(props.campaign.value));
    };

    watch(props.campaign, () => {
        resetCampaignLocal();
    });

    const onSubmit = () => {
        const campaign = JSON.parse(JSON.stringify(campaignLocal));
        campaign.value.targeted_activities = campaign.value.targeted_activities.map(activity => activity.value);
        campaign.value.included_entity_types = campaign.value.included_entity_types.map(activity => activity.name);


        // * If event has id => Edit Event
        // Emit event for add/update event
        if (props.campaign.value.id) emit('update-campaign', campaign.value);
        else emit('add-campaign', campaign.value);

        // Close sidebar
        emit('update:is-campaign-handler-sidebar-active', false);
    };

    const orderOptions= ref([]);
    const entityTypes= ref([]);
    const businessActivities = ref([]);

    const getOrders = async() =>{
        const orders = await Campaign.getOrderOfIncorporationDate();
        const arrayFailed = Object.entries(orders.data).map(arr => ({
            id: arr[0],
            name: arr[1],
        }));

        orderOptions.value = arrayFailed.map(obj => {
            const {id} = obj;
            const {name} = obj;
            return { id, name };
        });
    };

    getOrders();

    const getEntityTypes = async() =>{
        const data = await Company.getEntityTypes();
        const arrayFailed = Object.entries(data.data).map(arr => ({
            id: arr[0],
            name: arr[1],
        }));

        entityTypes.value = arrayFailed.map(obj => {
            const {id} = obj;
            const {name} = obj;
            return { id, name };
        });
    };

    const getBusinessActivities = async() =>{
        const data = await Company.getBusinessActivities();
        
        businessActivities.value = data.data.data;
    };

    getEntityTypes();
    getBusinessActivities();

    if (campaignLocal.value.order_of_incorporation_date) {
        // eslint-disable-next-line prefer-destructuring
        campaignLocal.value.order_of_incorporation_date = orderOptions.value.filter(
            opt => opt.value === campaignLocal.value.order_of_incorporation_date
        )[0];
    }


    return {
        campaignLocal,
        resetCampaignLocal,
        orderOptions,
        entityTypes,
        businessActivities,

        // UI
        onSubmit,
    };
}
