/* eslint-disable */
import { ref, watch, computed } from '@vue/composition-api';

export default function useShareTypesList() {
    // Use toast
    const refListTable = ref(null);

    const perPage = ref(25);
    const totalRows = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
        totalRows.value = localItemsCount;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRows.value,
        };
    });

    const refetchData = () => {
        refListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();

        if(searchQuery.value){
            refListTable.value.localItems = refListTable.value.localItems.filter(
                item =>
                    Object.keys(item).some(key => {
                        if (item[key] !== null && typeof item[key] === "object") {
                            return Object.keys(item[key]).some(nestedKey =>
                                item[key][nestedKey]
                                    .toString()
                                    .toLowerCase()
                                    .trim()
                                    .includes(searchQuery.value.toLowerCase())
                            );
                        } else if (item[key] !== null && item[key] !== undefined) {
                            return item[key]
                                .toString()
                                .toLowerCase()
                                .trim()
                                .includes(searchQuery.value.toLowerCase());
                        }
                        return false;
                    })
            );
        };
    });

    const resolveShareTypeRoleVariant = role => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'danger';
        return 'primary';
    };

    const resolveShareTypeRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon';
        if (role === 'author') return 'SettingsIcon';
        if (role === 'maintainer') return 'DatabaseIcon';
        if (role === 'editor') return 'Edit2Icon';
        if (role === 'admin') return 'ServerIcon';
        return 'UserIcon';
    };

    return {
        perPage,
        currentPage,
        totalRows,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,

        resolveShareTypeRoleVariant,
        resolveShareTypeRoleIcon,
        refetchData,
    };
}
