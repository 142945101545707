import axios from '@/apis/axios';

class Company
{
    static async all(status) {
        await axios.get("/sanctum/csrf-cookie");
        if (status === 'leads'){
            const response = await axios.get('/api/companies/leads');
            return response.data.data.data;
        }
        const response = await axios.get(`/api/companies/get-ads/${status}`);
        return response.data.data.data;
    }

    static async remove(id, data, status)
    {
        const response =  await axios.delete(`api/companies/${id}/${status}`, data);
        return response;
    }

    static async delete(id)
    {
        const response =  await axios.delete(`api/companies/${id}`);
        return response;
    }

    static async updateAd(id, status)
    {
        const response =  await axios.post(`api/companies/update-status/${id}/${status}`);
        return response;
    }

    static async printAddress(data)
    {
        const response = await axios.get(`api/companies/print-address`, { responseType: 'arraybuffer', params: data, "Access-Control-Allow-Credentials" : "true" });
        return response;
    }

    static getEntityTypes()
    {
        return axios.get('/api/companies/getEntityTypes');
    }

    static getBusinessActivities()
    {
        return axios.get('/api/campaigns/business-activities');
    }

    static update(companyId, data)
    {
        return axios.put(`/api/companies/${companyId}`, data);
    }
}

export default Company;
