<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="isCampaignHandlerSidebarActive"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="
                (val) => $emit('update:is-campaign-handler-sidebar-active', val)
            "
            @hidden="clearForm"
        >
            <template #default="{ hide }">
                <!-- Header -->
                <div
                    class="
                        d-flex
                        justify-content-between
                        align-items-center
                        content-sidebar-header
                        px-2
                        py-1
                        side-bar-header
                    "
                >
                    <h3 class="mb-0">
                        {{ campaignLocal.id ? "Update Campaign" : "Add Campaign" }}
                    </h3>

                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>

                <!-- Body -->
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                    <!-- Form -->
                    <b-form
                        class="p-2"
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetForm"
                    >
                        <!-- Title -->
                        <validation-provider
                            #default="validationContext"
                            name="campaign"
                            rules="required"
                        >
                            <b-form-group label="Campaign Name" label-for="campaign">
                                <b-form-input
                                    id="campaign"
                                    v-model="campaignLocal.name"
                                    data-test ="campaign-name"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Campaign Name"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            v-slot="{ errors }"
                            name="Business activity"
                        >
                            <!-- :rules="{ required: required, vselect: true }" -->
                            <b-form-group
                                label="Business activity"
                                label-for="business-activity"
                                class="end-align-indicator-middle-select"
                                :state="errors.length > 0 ? false : null"
                            >
                                <v-select
                                    id="business-activity"
                                    ref="primary"
                                    v-model="campaignLocal.targeted_activities"
                                    multiple
                                    class="custom-select-icon"
                                    :clearable="false"
                                    placeholder="Select Business Activity"
                                    label="text"
                                    :options="businessActivities"
                                    :selectable="option => !option.hasOwnProperty('group')"
                                >
                                <template #option="{ group, text }">
                                    <div v-if="group" class="group" >
                                        <span class="d-none">{{ group }}</span>
                                        {{ text }}
                                    </div>
                                </template>

                                </v-select>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <validation-provider
                                #default="{ errors }"
                                name="included entity types"
                                rules="required"
                        >
                            <b-form-group label="Entity Types - Included">
                                <v-select
                                        v-model="campaignLocal.included_entity_types"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        placeholder="Add included entity types"
                                        label="name"
                                        :options="entityTypes"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>


                        <validation-provider
                                #default="{ errors }"
                                name="order of incorporation date"
                                rules="required"
                        >
                            <b-form-group label="Order of Incorporation Date">
                                <v-select
                                        ref="order "
                                        v-model="campaignLocal.order_of_incorporation_date"
                                        class="custom-select-icon"
                                        :clearable="false"
                                        placeholder="Select Order"
                                        label="name"
                                        :options="orderOptions"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>

                        <b-form-checkbox
                                v-model="campaignLocal.include_corporate_secretary_firm"
                        >
                            Include Corporate Secretary Firm
                        </b-form-checkbox>


                        <!-- Form Actions -->
                        <div class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="
                                    mr-1
                                    mt-1
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                                type="submit"
                                data-test="create-campaign-btn"
                                >
                                {{ campaignLocal.id ? "Update" : "Add " }}

                                <img
                                    v-if="campaignLocal.id ? '' : 'Add'"
                                    :src="'/illustrations/add-circle.svg'"
                                    class="ml-1"
                                    alt="add-logo"
                                />

                                <img
                                    v-if="campaignLocal.id ? 'Update' : ''"
                                    :src="'/illustrations/edit.svg'"
                                    class="ml-1"
                                    alt="edit-logo"
                                />
                            </b-button>

                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                class="
                                mt-1
                                d-flex
                                justify-content-center
                                align-items-center
                                "
                                variant="outline-primary"
                                @click="$emit('update:is-campaign-handler-sidebar-active', false)"
                                >Cancel
                                <img
                                    :src="'/illustrations/cancel-circle.svg'"
                                    class="float-right ml-1"
                                    alt="cancel-logo"
                                />
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox
} from "bootstrap-vue";
import vSelect from 'vue-select';
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import useCampaignHandler from "./useCampaignHandler";

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        vSelect,
        BFormInvalidFeedback,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isCampaignHandlerSidebarActive",
        event: "update:is-campaign-handler-sidebar-active",
    },
    props: {
        isCampaignHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },

        campaign: {
            type: Object,
            required: true,
        },

        clearCampaignData: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            required,
            email,
            datePickerConfig: {
                altInput: true,
                altFormat   : "d M Y",
                dateFormat  : "Y-m-d",
            },
        };
    },

    setup(props, { emit }) {
        const {
            campaignLocal,
            orderOptions,
            entityTypes,
            businessActivities,
            // UI
            onSubmit,
        } = useCampaignHandler(toRefs(props), emit);

        const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(campaignLocal, props.clearCampaignData);


        return {
            // Add New
            campaignLocal,
            orderOptions,
            entityTypes,
            businessActivities,
            onSubmit,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,
        };
    },
};
</script>

<style scope>
.side-bar-header{
   background: #f6f6f6 !important;
 }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
