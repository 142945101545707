<template>
  <div>  
    <b-modal ref="confirmationModal" size="sm" centered hide-header footer-class="pb-2 justify-content-center border-0">
        <template>
        <slot name="body">Modal Body with button</slot>
        </template>

        <template #modal-footer="{ ok, cancel }">
            <slot name="footer">
                <b-button size="sm" variant="primary" @click="ok()">
                    Yes Delete it!
                </b-button>

                <b-button size="sm" variant="outline-danger" @click="cancel()">
                    Cancel
                </b-button>
            </slot>
        </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';

export default {
    components:{
        BModal,
        BButton,
    },
};

</script>
