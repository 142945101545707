<template>
    <div>

        <table-and-header
                ref="table"
                :campaign-list= "campaigns"
                :is-fetching-data= "isFetchingData"
                @addNewCampaign = "handleCampaignCreateOrEdit"
                @editCampaign="handleCampaignCreateOrEdit"
                @removeCampaign="removeCampaign"
        />


        <create-or-edit
                v-model="isCampaignHandlerSidebarActive"
                :campaign="campaign"
                :clear-campaign-data="clearCampaignData"
                @add-campaign="addCampaign"
                @update-campaign="updateCampaign"
        />

    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { useRouter } from '@core/utils/utils';
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store/index';
import CreateOrEdit from './CreateOrEdit.vue';
import TableAndHeader from './TableAndHeader.vue';
import { errorToast, successToast } from '@/views/helpers/toast';
import { SOMETHING_WENT_WRONG } from '@/constants/errors';
import Campaign from '@/models/Campaign';

export default {
    components: {
        CreateOrEdit,
        TableAndHeader,
    },


    setup() {
        const isBusy = true;
        const isLoading = ref(true);
        const campaigns = ref([]);
        const isFetchingData = ref(false);

        const { route } = useRouter();
        const routeParams = computed(() => route.value.params);

        watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
            fetchCampaigns();

        });

        const isCampaignHandlerSidebarActive = ref(false);

        const campaignBluePrint = {
            id: undefined,
            name: undefined,
            targeted_activities: undefined,
            excluded_activities: undefined,
            order_of_incorporation_date: undefined,
            include_corporate_secretary_firm: undefined,
            excluded_company_names: undefined,
            included_entity_types: undefined,
        };

        const campaign = ref(JSON.parse(JSON.stringify(campaignBluePrint)));

        const setCampaign = dataCampaign => ({
            id: dataCampaign.id,
            name: dataCampaign.name,
            targeted_activities: dataCampaign.targeted_activities.join(', '),
            included_entity_types: dataCampaign.included_entity_types.join(', '),
            order_of_incorporation_date: dataCampaign.order_of_incorporation_date,
            include_corporate_secretary_firm: dataCampaign.include_corporate_secretary_firm ? 'included' : 'excluded'
        });

        const fetchCampaigns = async() => {
            isFetchingData.value = true;
            campaigns.value = [];

            const campaignList = await Campaign.all();

            campaignList.forEach(dataCampaign => {
                const data = setCampaign(dataCampaign);
                campaigns.value.push(data);
            });

            isFetchingData.value = false;
        };
        fetchCampaigns();

        const clearCampaignData = () => {
            campaign.value = JSON.parse(JSON.stringify(campaignBluePrint));
        };

        const addCampaign = newCampaign => {
            Campaign.create(newCampaign)
                .then( payload => {
                    // eslint-disable-next-line no-use-before-define
                    const data = setCampaign(payload);

                    campaigns.value.push(data);
                    store.commit('auth/SET_CAMPAIGN', data);
                    store.commit('auth/ADD_CAMPAIGN', data);
                    successToast("New campaign has been created successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to store campaign.",
                        e
                    );
                });
        };

        const updateCampaign = campaignUpdate => {
            Campaign.update(campaignUpdate.id, campaignUpdate)
                .then( () => {
                    fetchCampaigns();
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to update campaign.",
                        e
                    );
                });
        };

        const removeCampaign = campaignToDelete => {
            Campaign.delete(campaignToDelete.item.id, campaignToDelete.item)
                .then(() => {
                    campaigns.value = campaigns.value.filter( exitingCampaign =>
                    // eslint-disable-next-line no-use-before-define
                        exitingCampaign.id !== campaignToDelete.item.id
                    );
                    successToast("Record has been deleted successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to delete campaign.",
                        e
                    );
                });
        };


        const handleCampaignCreateOrEdit = (toEditCampaign = campaignBluePrint, status = "create") => {
            if(status === 'create') campaign.value = campaignBluePrint;
            else
            {
                campaign.value = {...toEditCampaign.item};
                campaign.value.targeted_activities = campaign.value.targeted_activities.split(', ');
                campaign.value.included_entity_types = campaign.value.included_entity_types.split(', ');
                campaign.value.order_of_incorporation_date = { 'id' : (campaign.value.order_of_incorporation_date === "Ascending") ? 'asc' : 'desc' , 'name' : campaign.value.order_of_incorporation_date};
                campaign.value.include_corporate_secretary_firm = campaign.value.include_corporate_secretary_firm === 'included';
            }

            isCampaignHandlerSidebarActive.value = true;
        };


        return {
            fetchCampaigns,

            // Filter
            avatarText,

            // methods
            handleCampaignCreateOrEdit,

            // data
            isBusy,
            isLoading,

            isFetchingData,
            campaign,
            campaigns,
            isCampaignHandlerSidebarActive,
            clearCampaignData,
            addCampaign,
            updateCampaign,
            removeCampaign
        };
    },

    mounted()
    {
        // just to make sure the menu bar is shown
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.table-container {
  position: relative;
  width: 100%;
}

.b-table[aria-busy="true"] + .table-spinner {
  /* this assumes that the spinner component has a width and height */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* make sure spinner is over table */
}

.preloader div:before {
    background-color: var(--primary-color) !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
